nav li{
    display: list-item;
    //padding-right: 5%;
    font-weight: bold;
    margin-left: 10px; //14 may
    margin-right: 10px;
    text-decoration: underline;
    }

nav a{
    color: black;
}

.btn-outline-primary{
padding-top: 10px;
}

.btn-outline-secondary{
   padding-top: 10px;
}

#skip a {
    display: block;
    position: absolute;
    left: -999px;
    top: -999px;
    }

#skip a:focus {
    left: 0;
    top: 0;
    padding: 3px;
    background: #F9DBBE;
    border:1px solid #F9DBBE;
    }

.footer-links {
display: flex;
justify-content: space-between;
align-items: center;
}