.news-item {
    margin-bottom: 20px;
}

.container {
      max-width: 60rem;
      margin: 0 auto;
      padding: 10px;
      text-align: center;
  }
  
  .mycontainer {
      text-align: left;
      padding-right: 20px;
      padding-left: 15px;
  }

  .you-are-here {
     font-size: 1.25em;
     color: #000;
     font-family: Charter;
     font-weight: bold;
  }

  .modal {
	display: none;
	position: fixed;
	top: 5%;
	left: 0;
	right: 0;
	margin: 0 auto 0 auto;
	background: white;
	max-width: 500px;
	padding: 40px;
	border-radius: 3px;
	box-shadow: 0 2px 5px rgba(0,0,0,.3);
	z-index: 2;
}

.modal-overlay {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	cursor: pointer;
	background: rgba(0,0,0,.4);
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.spotlight{
  width: 100%;
  height: auto;
	background: white;
	padding: 3px;
	border-radius: 10px;
	box-shadow: 0 1px 5px rgba(0,0,0,.3);
}

  .announcement {
    display: inline-block;
    height: 100%;
    text-align: center;
    width: 100%;
    background: #D3D3D3;
    color: black;
    margin: 0;
    padding: 0%;
    padding-bottom: 0%;
    vertical-align: middle;
    border: solid black 3px;
  }
  
  .announcement a { 
    color: black; 
  }
  
  .announcement a:hover {
    color: #0000EE;
  }

  @media screen and (min-width: 480px){
  .spotlight{
    padding: 0% 20%;
  }
}
