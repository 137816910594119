.bg-general-land{
background: #F9DBBE;
border-radius: 12px;
}

.bg-general-vol{
background: #F9DBBE;
border-radius: 12px;
}

.bg-general-chap{
background:#F9DBBE;
border-radius: 12px;
}

.bg-blog-land{
background: #BFE8F8;
border-radius: 12px;
}

.bg-blog-vol{
background: #C3E6EE;
border-radius: 12px;
}

.bg-blog-chap{
background: #CDE2EF;
border-radius: 12px;
}

.bg-blog-sect{
background: #CFE8F2;
border-radius: 12px;
}

.bg-blog-note{
background: #C7E3F5;
border-radius: 12px;
}

.bg-guide-land{
background: #CBFBCB;
border-radius: 12px;
}

.bg-guide-vol{
background: #CBE7CB;
border-radius: 12px;
}

.bg-guide-chap{
background: #BDEABD;
border-radius: 12px;
}

.bg-guide-sect{
background: #C8E9C8;
border-radius: 12px;
}

.bg-guide-note{
background: #C9E8C9;
border-radius: 12px;
}

.bg-nav-header{
background: #D3D3D3;
border-radius: 12px;
}

.bg-nav-footer{
    background: #D3D3D3;
    border-radius: 12px;
}

.bg-background{
background: #F8F5E9;
}

.hidden-text{
display: none;
}

.hidden-text{
border: none;
}

#morevolunteer1{
display: none;
}

#morevolunteer2{
display: none;
}

#morevolunteer3{
display: none;
}

#morevolunteer4{
display: none;
}