    .figure-container {
      text-align: center;
      margin: 20px;
    }
    .figure-img {
      max-width: 100%;
      height: auto;
    }
    .btn-group {
      margin-top: 10px;
    }

.social-strip-icons {
    width: 24px; /* Set a fixed width */
    height: 24px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

.image-text-container {
width: 450px;  /* Fixed width */
height: 350px; /* Fixed height */
overflow: hidden; /* Ensure content does not overflow */
display: flex;
align-items: centre;
justify-content: center;
}

.profile-pic {
    width: 150px; /* Set a fixed width */
    height: 150px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    border-radius: 50%; /* Make the image circular */
}

.allies-profile {
    width: 150px; /* Set a fixed width */
    height: 150px; /* Set a fixed height */
    object-fit: cover; /* Ensure the image covers the area without distortion */
    border-radius: 0%;
}

.news-thumbnail {
width: 100%;
height: auto;
max-width: 150px;
}

.hero-image{
    position:relative;
    height: 300px;
}

.hero-image2{
    position:relative;
     height: 220px;
}

.photo-of-daniel {
width: 50%;
} 

.img-powered-by-new {
width: 70%;
height: auto;
}

.license {
color: black;
padding-left: 5px;
margin-left: 10px;
display: block;
}

.daniel-profile{
padding-top: 22px;
padding-right: 0px;
}

.danielle-profile{
padding-top: 22px;
padding-left: 5px;
padding-right: 0px;
}

.profile-photo{
padding-top: 0px;
padding-right: 10px;
padding-bottom: 10px;
}

.cover-image{
width: 35%;
height: auto;
display: block;
}

.blog-cover {
width: 200px;
justify-content: center;
padding-top: 10px;
padding-right: 10px;
padding-bottom: 10px;
}

.feature-feed{
    padding: 5%;
    display: block;
    justify-content: center;
    grid-auto-flow: row;
    height: auto;
}

.feed{
    padding: 5%;
    display: block;
    justify-content: center;
    grid-auto-flow: row;
    height: auto;
}

.blog-feed{
    padding: 5%;
    display:block;
    justify-content: center;
    grid-auto-flow: row;
    height: auto;
}

.news-image{
    padding-top: 5%;
    max-width: 80%;
    display: block;
}

.thumbnail-image-blog1{
    padding-top: 5%;
    max-width: 80%;
    display: block;
}

.thumbnail-image-blog{
    padding-top: 5%;
    max-width: 80%;
    display: block;
}

.thumbnail-image{
    padding-top: 5%;
    max-width: 80%;
    display: block;
}

.news-image-testimonials{
    padding-top: 5%;
    max-width: 80%;
    display: block;
}

@media screen and (max-width:480px){
    .img-powered-by-new{
        padding: 0% 20%;
    }
}

@media screen and (max-width:480px){
    .img-follow-us-new{
        padding-right: 0%;
    }
}

@media screen and (min-width: 480px){
.feed{
    padding: 18%;
    display: grid;
    justify-content: center;
    grid-auto-flow: row;
    align-content: center;
    height: auto;
    row-gap: 5%;
}
}

@media screen and (min-width: 480px){
.news-image {
    max-width: 100%;
    display: block;
    padding-top: 5%;
}
}

@media screen and (min-width: 480px) {
.thumbnail-image-blog1{
    max-width: 100%;
    display: block;
    padding-top: 5%;
}
}

@media screen and (min-width: 480px) {
.thumbnail-image-blog{
max-width: 100%;
display: block;
padding: 2%;
}
}

@media screen and (min-width: 480px) {
    .thumbnail-image{
    max-width: 100%;
    display: block;
    padding: 2%;
    }
}

@media screen and (min-width: 480px) {
    .news-image-testimonials{
    max-width: 100%;
    display: block;
    padding: 5%; 
}
}