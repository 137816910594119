.fg-general-land{
background-color: #F9DBBE;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
border-radius: 12px;
margin-bottom: 10px;
margin-top: 10px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.call-to-action{
background-color: #F9DBBE;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
border-radius: 12px;
margin-bottom: 10px;
margin-top: 50px;
display: inline-block;
text-align: center;
padding: 0 20px;
}

.fg-general-vol{
background-color: #F9DBBE;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
border-radius: 12px;
margin-bottom: 10px;
margin-top: 10px;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-general-chap{
background-color: #F9DBBE;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
border-radius: 12px;
margin-bottom: 10px;
margin-top: 10px;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.next-post{
background-color: #D3D3D3;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
width: fit-content;
height: fit-content;
border-radius: 22px;
margin-top: 15px;
margin-bottom: 15px;
padding: 0px 10px;
text-align: left;
}

.previous-post{
background-color: #D3D3D3;
font-size: 20px;
color: black;
border: solid rgb(0, 0, 0) 3px;
font-weight: bold;
width: fit-content;
height: fit-content;
border-radius: 22px;
margin-top: 15px;
margin-bottom: 15px;
padding: 0px 10px;
white-space: normal;
text-align: left;
}

.fg-guide-land{
background-color: #CBFBCB;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-guide-vol{
background-color: #CBE7CB;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-guide-chap{
background-color: #BDEABD;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
            
.fg-guide-sect{
background-color: #C8E9C8;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-guide-note{
background-color: #C9E8C9;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-blog-land{
background-color: #BFE8F8;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
      
.fg-blog-vol{
background-color: #C3E6EE;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-blog-chap{
background-color: #CDE2EF;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-blog-sect{ 
background-color: #CFE8F2;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.fg-blog-note{ 
background-color: #C7E3F5;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mybtnmps {
background-color: #7ED27E;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
      
.mybtnmathematics {
background-color: #54B054;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mybtnclassicmechanics {
background-color: #54B054;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
  
.mybtnoscillations {
background-color: #54B054;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mybtnquantummechanics {
background-color: #54B054;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
  
.mybtncomputerliteracy {
background-color: #7ED27E;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}
  
.mybtnstatistics {
background-color: #7ED27E;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mybtncookbook {
background-color: #7ED27E;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mybtnvocal {
background-color: #7ED27E;
font-size: 20px;
color: black;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
text-align: center;
padding: 0 20px;
margin: 0;
}

.mysecondarybtn {
background-color: #ff0101;
border: solid black 3px;
font-weight: bold;
margin-top: 30px;
border-radius: 12px;
position: sticky;
display: inline-block;
overflow: hiddens;
font-weight: bold;
padding: auto;
}

.Read-More {
background-color: #D3D3D3;
border: solid black 3px;
font-weight: bold;
margin-top: 20px;
margin-bottom: 20px;
border-radius: 12px;
position: sticky;
display: inline-block;
overflow: hiddens;
font-weight: bold;
}

#Read-More-BTN1 {
background-color: #D3D3D3;
border: solid black 3px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 20px;
border-radius: 12px;
position: relative;
display: grid;
overflow: hidden;
font-weight: bold;
}

#Read-More-BTN2 {
background-color: #D3D3D3;
border: solid black 3px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 20px;
border-radius: 12px;
position: relative;
display: grid;
overflow: hidden;
font-weight: bold;
}

#Read-More-BTN3 {
background-color: #D3D3D3;
border: solid black 3px;
font-weight: bold;
margin-top: 10px;
margin-bottom: 20px;
border-radius: 12px;
position: relative;
display: grid;
overflow: hidden;
font-weight: bold;
}

#Read-More-BTN4 {
 background-color: #D3D3D3;
 border: solid black 3px;
 font-weight: bold;
 margin-top: 10px;
 margin-bottom: 20px;
 border-radius: 12px;
 position: relative;
 display: grid;
 overflow: hidden;
 font-weight: bold;
 }

.mybtswitch{
background: #D3D3D3;
border: solid black 3px;
font-weight: bold;
border-radius: 12px;
display: inline-block;
margin-bottom: 5px;
}

.volume-selector{
      width: 100%;
      overflow: unset;
      text-overflow: inherit;
      position: absolute;
      text-indent: unset;
      display: inline-block;
      vertical-align: middle;
} 

.mybtnswitch{
      background-color: #D3D3D3;
      border: solid black 3px;
      font-weight: bold;
      line-height: 1;
      margin-top: 3%;
      margin-left: 3%;
      width: 100%;
      border-radius: 20%;
}

.dropdown-item{
      background-color: #D3D3D3;
      border: solid black 3px;
      font-weight: bold;
      margin-left: 0%;
      margin-top: 5%;
      margin-bottom: 5%;
      border-radius: 22%;
      position: sticky;
      display: inline-block;
      overflow: hidden;
      font-weight: bold;
}

.mybtnquick{
      background-color: #D3D3D3;
      border: solid black 3px;
      font-weight: bold;
      line-height: 1;
      margin-top: 3%;
      margin-left: 3%;
      width: 100%;
      border-radius: 20%;
}

.mybtnquick a { 
      color: black; 
    }
    
.mybtnquick a:hover {
      color: #0000EE;
    }

a.disabled {
      /* Make the disabled links grayish*/
      color: rgb(167, 164, 164);
      opacity: 30%;
      /* And disable the pointer events */
      pointer-events: none;
    }

    @media screen and (min-width: 480px){
      .mybtnquick {
          max-width: 50%;
          display: block;
          padding: 1%;
      }
      }

      
    @media screen and (min-width: 480px){
      .mybtnswitch {
          max-width: 60%;
          display: block;
          padding: 1%;
      }
      }